//
// _layouts.scss
//
.table-wrap {
  table-layout: fixed;
  word-wrap: break-word;
}
.otp-container {
  display: flex; /* Gunakan Flexbox agar input sejajar secara horizontal */
  flex-direction: row; /* Arahkan item ke kanan (default) */
  justify-content: center; /* Pusatkan input secara horizontal */
  align-items: center; /* Pusatkan secara vertikal */
  gap: 10px; /* Jarak antar input */
}

.otp-input {
  text-align: center;
  font-size: 1.5rem;
  height: 50px;
  width: 50px;
  border: 3px solid #ced4da;
  border-radius: 5px;
  transition: border-color 0.3s;
}

.otp-input:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}



body[data-layout-size="boxed"] {
  background-color: $boxed-body-bg;
  #layout-wrapper {
    background-color: $body-bg;
    max-width: $boxed-layout-width;
    margin: 0 auto;
    box-shadow: $box-shadow;
  }

  #page-topbar {
    max-width: calc(#{$boxed-layout-width} - #{$sidebar-width});
    margin: 0 auto;
  }

  &.vertical-collpsed {
    #page-topbar {
      max-width: calc(#{$boxed-layout-width} - #{$sidebar-collapsed-width});
      margin: 0 auto;
    }
    .main-content {
      min-height: $layout-collapsed-min-height;
    }
  }

  &[data-sidebar-size="small"] {
    #page-topbar {
      max-width: calc(#{$boxed-layout-width} - #{$sidebar-width-sm});
      left: $sidebar-width-sm;
      @media (max-width: 991.98px) {
        left: 0;
      }
    }
  }

  .footer {
    margin: 0 auto;
    max-width: calc(#{$boxed-layout-width} - #{$sidebar-width});
  }

  &.vertical-collpsed {
    .footer {
      max-width: calc(#{$boxed-layout-width} - #{$sidebar-collapsed-width});
    }
  }
}

// Horizontal Boxed Layout

body[data-layout="horizontal"][data-layout-size="boxed"] {
  #page-topbar,
  #layout-wrapper,
  .footer {
    max-width: 100%;
  }
  .container-fluid,
  .navbar-header {
    max-width: $boxed-layout-width;
  }

  &.vertical-collpsed {
    .main-content{
      min-height: auto;
    }
  }
}

// Scrollable layout

body[data-layout-scrollable="true"] {
  @media (min-width: 992px) {
    #page-topbar,
    .vertical-menu,
    .navbar-brand-box {
      position: absolute;
    }
  }

  &[data-layout="horizontal"] {
    @media (min-width: 992px) {
      #page-topbar,
      .topnav {
        position: absolute;
      }
      .navbar-brand-box {
        position: relative;
      }
    }
  }
}
